/*----------  Row Spacing  ----------*/

@mixin row-spacing($gutter: $grid-gutter-width)
{
	$gutter: nth($gutter, 1);

	margin-left: ($gutter / -2) + px;
	margin-right: ($gutter / -2) + px;

	> .#{$col-name}
	{
		padding-left: ($gutter / 2) + px;
		padding-right: ($gutter / 2) + px;
	}
}

/*----------  Row Break  ----------*/

@mixin row-break() {
	@include row-spacing(0);

	> .#{$col-name}
	{
		margin: 0;
		padding: 0;
		flex: auto;
		width: auto;
		max-width: auto;
	}
}