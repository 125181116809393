/*----------  Body  ----------*/

body
{
	@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
	@include position;
}

.container-md
{
	@include extend-container(850px, 30px);
	margin: auto;
}

.top-design
{
	@include position;
	&:before
	{
		content: '';
		background-image: url('../images/content_before.png');
		width: 580px;
		height: 295px;
		position: absolute;
		top: 0;
		left: 0;
		background-repeat: no-repeat;	
		background-position: top left;
		background-size: contain;
		z-index: 0;
	}

	&:after
	{
		content: '';
		background-image: url('../images/content_after.png');
		width: 450px;
		height: 300px;
		position: absolute;
		top: 0;
		right: 0;
		background-repeat: no-repeat;	
		background-position: top right;
		background-size: contain;
		z-index: 0;
	}

	@media (max-width: 767px) 
	{
		&:before
		{
			@include transform(scale(0.5));
			-webkit-transform-origin: top left;
			   -moz-transform-origin: top left;
			    -ms-transform-origin: top left;
			     -o-transform-origin: top left;
			        transform-origin: top left;
		}

		&:after
		{
			@include transform(scale(0.5));
			-webkit-transform-origin: top right;
			   -moz-transform-origin: top right;
			    -ms-transform-origin: top right;
			     -o-transform-origin: top right;
			        transform-origin: top right;
		}
	}
}

.btn-white-bordered
{
	@include font-p($font-md, $weight-bold, rem-calc(22), $white-color);
	background-color: transparent;
	border: 2px $white-color solid;
	@include border-radius(0);

	&:hover, &:active, &:focus
	{
		background-color: $white-color;
		color: $brand-primary-01;
	}
}

.btn-base
{
	@include font-p($font-md, $weight-bold, rem-calc(22), $white-color);
	background-color: $brand-primary-01;
	border: 2px $brand-primary-01 solid;
	@include border-radius(0);

	&:hover, &:active, &:focus
	{
		background-color: transparent;
		color: $brand-primary-01;
	}
}

.btn-base-bordered
{
	@include font-s(rem-calc(14), $weight-bold, rem-calc(20), $brand-primary-01);
	background-color: transparent;
	border: 2px $brand-primary-01 solid;
	@include border-radius(0);
	min-width: 120px;


	&:hover, &:active, &:focus
	{
		background-color: $brand-primary-01;
		color: $white-color;
	}
}

.btn-close
{
	@include position(absolute, 2, 0, auto, 0, auto);
	@include transform(translate(50%, -50%));
	background-color: $brand-success-01;
	padding: 17px;
	margin-bottom: 0;
	@include border-radius(999px);

	&:hover, &:active, &:focus
	{
		background-color: #13993D;
	}
}

h2
{
	@include font-p(rem-calc(32), $weight-bold, 1.2, $brand-primary-01);
	margin-bottom: 30px;

	&.sm { font-size: rem-calc(30px); }

	@media (max-width: 575px) 
	{
		font-size: rem-calc(26);
		margin-bottom: 20px;

		&.sm { font-size: rem-calc(24); }
	}
}

h3
{
	@include font-p(rem-calc(28), $weight-bold, rem-calc(33), $brand-primary-01);
	margin-bottom: 20px;

	@media (max-width: 575px) 
	{
		font-size: rem-calc(22);
		margin-bottom: 10px;
	}
}

h4
{
	@include font-p(rem-calc(24px), $weight-bold, 1.3, $brand-primary-01);
	margin-bottom: 20px;

	@media (max-width: 575px) 
	{
		font-size: rem-calc(20px);
		margin-bottom: 10px;
	}
}

h5
{
	@include font-p(rem-calc(18px), $weight-bold, 1.3, $brand-primary-01);
	margin-bottom: 20px;

	@media (max-width: 575px) 
	{
		font-size: rem-calc(16px);
		margin-bottom: 10px;
	}
}

a
{
	@include font-s(rem-calc(16), $weight-bold, rem-calc(24), $brand-primary-01);
	@include transition;
	word-break: break-word;
	&:hover, &:active, &:focus
	{
		color: darken($brand-primary-01, 5%);
	}

	&.bordered
	{
		span {
			@include position;
			&:after
			{
				content: '';
				@include position(absolute, 2, auto, 0, 0, 4px);
				height: 1px;
				background-color: $brand-primary-01;
				width: 100%;
			}
		}
	}
}

p
{
	@include font-s(rem-calc(16), $weight-regular, rem-calc(24), $brand-gray-04);
	letter-spacing: 0.5px;
}

.btn
{
	@include border-radius(0);

	&:hover, &:active, &:focus
	{
		@include box-shadow(none);
	}
}

.btn-teal
{
	@include font-s(rem-calc(14), $weight-bold, rem-calc(19), $white-color);
	background-color: $brand-primary-02;

	&:hover, &:active, &:focus {
		color: $white-color;
	}
}

.btn-yellow
{
	@include font-p(rem-calc(18), $weight-bold, rem-calc(22), $brand-primary-01);
	background-color: $brand-warning-02;
	&:hover, &:active, &:focus {
		background-color: darken($brand-warning-02, 5%);
		color: $brand-primary-01;
	}
}

.btn-green
{
	@include font-p(rem-calc(18), $weight-bold, rem-calc(22), $white-color);
	background-color: $brand-success-01;
	&:hover, &:active, &:focus {
		background-color: darken($brand-success-01, 5%);
		color: $white-color;
	}
}

.btn-gray
{
	@include font-p(rem-calc(18), $weight-bold, rem-calc(22), $white-color);
	background-color: $brand-gray-10;
	&:hover, &:active, &:focus {
		background-color: darken($brand-gray-10, 5%);
		color: $white-color;
	}
}

.secondary {
	font-family: $font-primary;
}

.link-base
{
	@include font-p(rem-calc(24), $weight-bold, rem-calc(29), $brand-primary-01);
}

.link-gray
{
	@include font-s(rem-calc(16), $weight-regular, rem-calc(21), $brand-gray-11);

	&:hover, &:active, &:focus
	{
		color: darken($brand-gray-11, 10%);
	}
}

.link-icon
{
	.la
	{
		@include transition;
		font-size: rem-calc(26px);
		vertical-align: bottom;
		margin-right: 5px;
	}

	&:hover, &:active, &:focus
	{
		.la
		{
			@include transform(translateX(-5px));
		}
	}
}

.text-olive
{
	color: $brand-olive-01 !important;
}

.bg-yellow
{
	background-color: $brand-warning-03;
}

.bg-green
{
	background-color: $brand-success-01;
}

.bg-blue
{
	background-color: $brand-primary-03;
}

.bg-teal
{
	background-color: $brand-teal-01;
}

.bg-light-gray
{
	background-color: $brand-gray-03;	
}

.bg-white
{
	background-color: $white-color;	
}

.bg-primary-01
{
	background-color: $brand-primary-01;	
}

.mt-60
{
	margin-top: 60px !important;
}

.mb-60
{
	margin-bottom: 60px !important;
}

.news-tag
{
	@include font-p(rem-calc(14), $weight-bold, rem-calc(17), $brand-gray-02);
	background-color: $brand-warning-02;
	padding: 2px 4px;
	margin-bottom: 6px;

	&:hover, &:active, &:focus
	{
		background-color: darken($brand-warning-02, 5%);
	}
}

.section-design2
{
	background-color: $brand-primary-01;
	@include position;
	overflow-y: hidden;
	
	&:before
	{
		content: url('../images/contact-map-bg-before.png');
		position: absolute;
		bottom: -4px;
		left: 0;
		z-index: -1;
	}

	&:after
	{
		content: url('../images/contact-map-bg-after.png');
		position: absolute;
		top: 0px;
		right: 0;
		z-index: -1;
	}

	@media (max-width: 767px) 
	{
		&:before
		{
			@include transform(scale(0.5));
			-webkit-transform-origin: bottom left;
			   -moz-transform-origin: bottom left;
			    -ms-transform-origin: bottom left;
			     -o-transform-origin: bottom left;
			        transform-origin: bottom left;
		}

		&:after
		{
			@include transform(scale(0.5));
			-webkit-transform-origin: top right;
			   -moz-transform-origin: top right;
			    -ms-transform-origin: top right;
			     -o-transform-origin: top right;
			        transform-origin: top right;
		}
	}
}

.page-title-wrapper
{
	@include background-defaults;
	margin-bottom: 50px;

	.page-title-container
	{
		text-align: center;
		height: 135px;
		@include display-inline-flex(center, center);
	}

	@media (max-width: 767px) 
	{
		margin-bottom: 30px;
	}
}

.page-title
{
	@include font-p(rem-calc(36), $weight-bold, rem-calc(45), $white-color);

	@media (max-width: 575px) 
	{
		font-size: rem-calc(28);
	}
}

.post-content
{
	// h2
	// {
	// 	@include font-p(rem-calc(32), $weight-bold, rem-calc(38), $brand-primary-01);
	// 	margin-bottom: 20px;
	// }

	// h3
	// {
	// 	
	// }

	iframe
	{
		max-width: 100%;
	}

	p, a
	{
		@include font-s(rem-calc(16), $weight-regular, rem-calc(24), $brand-gray-04);
		margin-bottom: 30px;

		strong
		{
			color: $brand-gray-10;
		}

		&.text-white
		{
			strong
			{
				color: $white-color;
			}			
		}

		a
		{
			margin-bottom: 0;
		}
	}

	a
	{
		color: $brand-primary-01;
		font-weight: $weight-bold;
		margin-bottom: 10px;
		line-height: 1.1;

		&:hover, &:active, &:focus
		{
			color: darken($brand-primary-01, 10%)

			// &:before
			// {
			// 	color: darken($brand-primary-01, 10%)
			// }
		}
	}

	img
	{
		margin-bottom: 20px;
	}

	h4
	{
		@include font-s(rem-calc(16), $weight-bold, rem-calc(24), $brand-gray-10);
		margin-bottom: 20px;
	}

	ul
	{
		@include reset-list;
		margin-bottom: 30px;

		li
		{
			@include font-s(rem-calc(16), $weight-regular, rem-calc(24), $brand-gray-04);
			@include position;
			>a
			{
				padding-left: 23px;
				&:before
				{
					@include transition;
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					font-family: "Font Awesome 5 Free"; 
					font-weight: 900;
					content: '\f105';
					color: $brand-primary-01;
					position: absolute;
					left: 0;
				}

				&:hover, &:active, &:focus
				{
					&:before
					{
						color: darken($brand-primary-01, 10%);
						@include transform(translateX(3px));
					}
				}
			}
		}

		.fa-icon
		{
			margin-right: 10px;
		}
	}

	.btn-teal
	{
		height: 40px;
		@include font-p(rem-calc(18), $weight-bold, rem-calc(22), $white-color);
		background-color: $brand-teal-03;

		&:hover, &:active, &:focus
		{
			color: $white-color;
			background-color: darken($brand-teal-03, 5%);
		}
	}

	&.post-content-white
	{
		h2, h3, h4, h5, p, a 
		{
			color: $white-color;
			strong
			{
				color: $white-color;
			}
		}

		a
		{
			&:hover, &:active, &:focus
			{
				color: $white-color;
			}
		}

		ul
		{
			li
			{
				a
				{
					&:before
					{
						color: $white-color;
					}

					&:hover, &:active, &:focus
					{
						&:before
						{
							color: $white-color;
						}
					}
				}
			}
		}
	}
}

.custom-checkbox-container
{
	@include position;
	.custom-checkbox {
		position: absolute; // take it out of document flow
		opacity: 0; // hide it

		& + label {
			position: relative;
			cursor: pointer;
			// display: block;
			padding: 0;
			margin-bottom: 0;
			@include font-p(rem-calc(14px), $weight-bold, 1.2, $brand-gray-17);
		}

		// Box.
		& + label:before {
			content: '';
			margin-right: 6px;
			display: inline-block;
			vertical-align: text-top;
			width: 16px;
			height: 16px;
			background: transparent;
			border: 2px solid $brand-gray-17;
			@include border-radius(2px);
		}

		// Box hover
		&:hover + label:before {
			// background: $brand-gray-17;
		}

		&.subchecked + label:before {
			background: $brand-gray-17;
		}

		// Box checked
		&:checked + label:before {
			background: $brand-gray-17;
		}

		// Checkmark. Could be replaced with an image
		&:checked + label:after {
			content: '';
			position: absolute;
			left: 3px;
			top: 7px;
			background: white;
			width: 2px;
			height: 2px;
			box-shadow: 
				2px 0 0 white,
				4px 0 0 white,
				4px -2px 0 white,
				4px -4px 0 white,
				4px -6px 0 white,
				4px -8px 0 white;
			transform: rotate(45deg);
		}
	}

	&.custom-checkbox-primary {
		.custom-checkbox {
			& + label {
				color: $brand-primary-01;
				font-size: rem-calc(16px);
			}
			
			& + label:before {
				border: 2px solid $brand-primary-01;
			}

			// Box hover
			&:hover + label:before {
				// background: $brand-primary-01;
			}

			&.subchecked + label:before {
				background: $brand-primary-01;
			}

			// Box checked
			&:checked + label:before{
				background: $brand-primary-01;
			}
		}
	}
}

.tag-list {
	.tag-item {
		.tag {
			@include font-p(rem-calc(14), $weight-bold, rem-calc(17), $brand-gray-02);
			padding: 2px 4px;
			margin-bottom: 6px;

			// &:hover, &:active, &:focus
			// {
			// 	background-color: darken($brand-warning-02, 5%);
			// }

			&-yellow { 
				background-color: $brand-warning-02;  
				&:hover, &:active, &:focus { 
					// background-color: darken($brand-warning-02, 5%);
				} 
			}
			&-teal { 
				background-color: $brand-teal-02; color: $white-color; 
				&:hover, &:active, &:focus { 
					// background-color: darken($brand-teal-02, 5%);
				} 
			}
		}
	}
}

.modal
{
	.modal-body {
		padding: 86px 55px;

		.head {
			text-align: center;
			margin-bottom: 40px;

			p {
				@include font-s(rem-calc(18px), $weight-regular, 1.2, $brand-gray-17);
			}
		}

		.form-control {
			@include reset;
			@include font-p(rem-calc(16px), $weight-bold, 1.2, $brand-primary-01);
			padding: 10px 15px;
			@include placeholder($brand-gray-17);
			border: 2px solid $brand-gray-17;

			&:active, &:focus
			{
				border-color: $brand-primary-01;
				@include placeholder($brand-primary-01);
			}
		}

		.custom-checkbox-container.custom-checkbox-primary .custom-checkbox+label {
			@include font-s(rem-calc(16px), $weight-regular, 1.2, $brand-gray-17);
		}

		.foot {
			margin-top: 56px;
			text-align: center;
		}

		.btn
		{
			@include font-p(rem-calc(18), $weight-bold, 1.2, $white-color);
			padding: 10px auto;
			min-width: 218px;

			 &.closeBtn {
			 	display: none;
			 }
		}
	}

	&.virtual-library-popup {
		img, p {
			margin-bottom: 20px;
		}

		.content-foot {
			
		}
	}

	@include media-breakpoint-down(sm) { 
		.modal-body {
			padding: 50px 30px;
		}
	}

	@include media-breakpoint-down(xs) { 
		.btn-close {
			display: none;
		}

		.modal-body {
			padding: 40px 20px;

			.foot {
				@include display-flex(column, center, center);
				.btn {
					margin-bottom: 20px;
					&:last-child {
						margin-bottom: 0;
					}

					&.closeBtn {
						display: inline-block;
					}
				}
			}
		}
	}
}

.modal-backdrop
{
	background-color: rgba(78, 81, 82, 0.85);

	&.show {
		@include opacity(1);
	}
}