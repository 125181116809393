/*----------  Font Variables  ----------*/

$font-primary				: 'Advent Pro', sans-serif;
$font-secondary				: 'Helvetica', serif;
// $sub-font-01				: sans-serif;
// $sub-font-02				: sans-serif;

/*----------  Icon Fonts  ----------*/
$font-fontawesome			: 'FontAwesome';
$font-lineawesome			: 'LineAwesome';

/*----------  Color Variables  ----------*/

/*----------  Bootstrap's Defaults  ----------*/

$brand-primary				: #007bff;
$brand-secondary			: #868e96;
$brand-success				: #28a745;
$brand-info					: #17a2b8;
$brand-warning				: #ffc107;
$brand-danger				: #dc3545;
$brand-light				: #f8f9fa;
$brand-dark					: #343a40;

/*-----  Default Variables  -----*/

$white-color				: #fff !default;
$black-color				: #000 !default;

/*-----  Unsorted Variables  -----*/

// $color-01					: #;
// $color-02					: #;

/*-----  Brands Variables  -----*/

/* Brand Primary */
$brand-primary-01			: #0B457A;
$brand-primary-02			: #046879;
$brand-primary-03			: #0075B3;
$brand-primary-04			: #025665;
$brand-primary-05			: #0F416E;
$brand-primary-06			: #053561;

/* Brand Info */
// $brand-info-01				: #;
// $brand-info-02				: #;

/* Brand Warning */
$brand-warning-01			: #C9BE0B;
$brand-warning-02			: #EFE319;
$brand-warning-03			: #F2E61A;

/* Brand Success */
$brand-success-01			: #39AA35;
$brand-success-02			: #13993D;

/* Brand Danger */
// $brand-danger-01				: #;
// $brand-danger-02				: #;

/* Brand Gray */
$brand-gray-01				: #7A7A7A;
$brand-gray-02				: #343434;
$brand-gray-03				: #E7E7E7;
$brand-gray-04				: #868686;
$brand-gray-05				: #363636;
$brand-gray-06				: #565656;
$brand-gray-07				: #C1C1C1;
$brand-gray-08				: #0F0F0F;
$brand-gray-09				: #797979;
$brand-gray-10				: #4E5152;
$brand-gray-11				: #5A5A5A;
$brand-gray-12				: #F7F7F7;
$brand-gray-13				: #1B1B1B;
$brand-gray-14				: #F9F9F9;
$brand-gray-15				: #F1F1F1;
$brand-gray-16				: #FBFBFB;
$brand-gray-17				: #4e5152;
$brand-gray-18				: #ebebeb;

/* Brand Teal */
$brand-teal-01				: #009998;
$brand-teal-02				: #1BA193;
$brand-teal-03				: #147D89;

/* Brand Olive */
$brand-olive-01				: #666105;


/*----------  Font Weight Variables  ----------*/

$weight-hairline 			: 100;
$weight-light	 			: 300;
$weight-regular 			: 400;
$weight-medium				: 500;
$weight-semibold 			: 600;
$weight-bold	 			: 700;
$weight-extrabold	 		: 900;

/*----------  Font Size Variables  ----------*/

$font-base					: rem-calc(16);					// 16px
$font-xs					: $font-base - rem-calc(4);		// 12px
$font-sm					: $font-base - rem-calc(2);		// 14px
$font-md					: $font-base + rem-calc(2);		// 18px
$font-lg					: $font-base + rem-calc(4);		// 20px
$font-xl					: $font-base + rem-calc(24);	// 40px
$font-xxl					: $font-base + rem-calc(44);	// 60px

/*----------  Grid  ----------*/

$grid-space-default			: 30;
$col-name					: 'cs-col';